<template>
    <div>
        <div v-if="is_show == 0">
            <div class="ExamListText">
                宣传申领
            </div>
            <div class="formBox">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="192px" class="demo-ruleForm">

                    <el-form-item label="姓名 ：" prop="user_name">
                        <el-input v-model="ruleForm.user_name" placeholder="请输入您的姓名"></el-input>
                    </el-form-item>

                    <el-form-item label="会员编号 ：" prop="user_number">
                        <el-input v-model="ruleForm.user_number" placeholder="请输入您的会员编号"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话 ：" prop="user_phone">
                        <el-input v-model="ruleForm.user_phone" placeholder="请输入您的联系电话"></el-input>
                    </el-form-item>

                    <el-form-item label="银行开户行名称 ：" prop="bank_name">
                        <el-input v-model="ruleForm.bank_name" placeholder="请输入您的银行开户行名称（精准到支行）"></el-input>
                    </el-form-item>
                    <el-form-item label="银行卡号 ：" prop="bank_number">
                        <el-input v-model="ruleForm.bank_number" placeholder="请输入您的银行卡号"></el-input>
                    </el-form-item>

                    <el-form-item label="银行预留手机号 ：" prop="bank_phone">
                        <el-input v-model="ruleForm.bank_phone" placeholder="请输入您的银行预留手机号"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="Submit1" @click="SubmitFrom('ruleForm')">
                申领二维码
            </div>
        </div>
        <div v-if="is_show == 1">
            <div class="ExamListText">
                宣传申领
            </div>
            <div class="QRcode">
                <!-- <img src="" alt="" id="img" width="100%" height="100%"> -->
                <el-image
                    src="https://ishow.qiniu.aiyuebang.com.cn/images/2023-04-21/e799a28a-395b-4660-9910-5057c7ae4591.png"
                    width="100%" alt="" v-if="orcode" :preview-src-list="[orcode]" @click.stop="handleClickItem" />
                <div v-else>-</div>
            </div>
            <div class="btnBox">
                <div class="Submit" @click="downhander">
                    下载专属宣传海报
                </div>
                <!-- <div class="Submit" @click="$router.push('/ApplyWithdrawal')">
                    分销记录
                </div> -->
            </div>
        </div>
        <img src="@/../public/image/weixintupian.png" alt="" id="ims" style="display:none" />
        <div v-if="dialog" class="dialogBox">
            <!--  另一个审核 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="fs22">请仔细核对所填信息，申领后不能进行修改。</p>
                <div class="btnBox">
                    <div class="cancel " @click="dialog = false">取消</div>
                    <div class="ml15 Submit2" @click="ApplyCode('ruleForm')">确认申领</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { PublicityApplication, is_QRcode } from '@/api/PublicityApplication'

export default {
    data() {
        return {
            page_size: 10,
            page: 1,
            total: 20,
            dialog: false,
            dialogVisible: true,
            is_show: 0,
            ruleForm: {
            },
            rules: {
                user_name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                user_number: [
                    { required: true, message: '请输入会员编号', trigger: 'blur' },
                ],
                user_phone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                ],
                bank_name: [
                    { required: true, message: '请输入银行开户行名称', trigger: 'blur' },
                ],
                bank_number: [
                    { required: true, message: '请输入银行卡号', trigger: 'blur' },
                ],
                bank_phone: [
                    { required: true, message: '请输入银行预留手机号', trigger: 'blur' },
                ],
            },
            value: '',
            orcode: ''
        }
    },
    mounted() {
        is_QRcode().then(res => {
            if (res.code == 200) {
                this.is_show = 1
                let that = this
                const img = document.getElementById('ims')
                var image = new Image();
                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous");
                image.onload = function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = img.width
                    canvas.height = img.height
                    var context = canvas.getContext("2d");
                    context.drawImage(img, 0, 0, img.width, img.height);
                    context.drawImage(image, 215, 5722, 320, 320);
                    var url = canvas.toDataURL(); //得到图片的base64编码数据
                    that.orcode = url
                };
                image.src = 'new/' + res.data.recommend_src
            }
        })

    },
    methods: {
        handleClickItem() {
            this.$nextTick(() => {
                let domImageMask = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
                if (!domImageMask) {
                    return;
                }
                domImageMask.addEventListener("click", () => {
                    // 点击遮罩层时调用关闭按钮的 click 事件
                    document.querySelector(".el-image-viewer__close").click();
                });
            });
        },
        pageChange(val) {
        },
        sizeChange(val) {
        },
        downhander() {
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "photo"; // 设置图片名称
            a.href = this.orcode; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
            // this.downloadImg('new/' + this.orcode, 'pic');
        },
        //申请二维码
        SubmitFrom(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.dialog = true
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // suaxin() {
        //     window.location.reload();
        // },
        //申请二维码
        ApplyCode() {
            PublicityApplication(this.ruleForm).then(res => {
                if (res.code == 200) {
                    this.$message.success('申请成功');
                    this.dialog = false
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            })
        },
    },
    watch: {
        dialogVisible(val) {
            if (val == false) {
                this.dialog = false
                this.dialogVisible = true
            }
        }
    }
}
</script>

<style  scoped>
.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}

.formBox {
    width: 90%;
    margin: 20px auto;
}

.formBox>>>.el-form-item__label {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.formBox>>>.el-input__inner {
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.Submit2 {
    width: 200px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.formBox>>>.el-textarea__inner {
    height: 178px;
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    border-radius: 4px;
}

.Submit {
    width: 260px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    margin-bottom: 0px;
    margin: 0 20px;
    cursor: pointer;
}

.Submit1 {
    width: 440px;
    height: 48px;
    background: #CC0000;
    cursor: pointer;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin: 100px auto;
    line-height: 48px;
    margin-bottom: 0px;
}



.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}


.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}


.cancel {
    width: 200px;
    height: 48px;
    background: #999999;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
}


.QRcode {
    width: 230px;
    height: 230px;
    padding: 13px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 2px solid #CD0000;
    margin: 50px auto;
}
</style>