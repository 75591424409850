import service from '@/utils/request2'

//申领二维码
export function PublicityApplication(data) {
    return service({
        url: '/api/api/distribution/toApply',
        method: 'post',
        data
    })
}
//获取专业
export function getMajorList(params) {
    return service({
        url: '/api/api/major',
        method: 'get',
        params
    })
}
//获取视频详情
export function getMajorDetails(params) {
    return service({
        url: '/api/api/curriculum/info',
        method: 'get',
        params
    })
}
//判断是否进入二维码页面
export function is_QRcode(params) {
    return service({
        url: '/api/api/distribution/isApply',
        method: 'get',
        params
    })
}

